<template>
  <div>
    <h2>ダッシュボード</h2>
    <div class="">
      管理者専用のダッシュボードです。必要な情報に簡単にアクセスできます。
    </div>
    <!-- <hr> -->
    <b-row class="match-height">
      <b-col lg="12">
        <ShortCut />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import ShortCut from '@/components/admin/dashboad/navigation/ShortCut.vue'

export default {
  components: {
    BRow,
    BCol,
    ShortCut,
  },
}
</script>
