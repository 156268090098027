<template>
  <div class="space-basic">
    <DashBoadMain />
  </div>
</template>

<script>
import DashBoadMain from '@/components/admin/dashboad/DashBoadMain.vue'

export default {
  components: {
    DashBoadMain,
  },
}
</script>
